<template>  
    <footer>
        <ul>
            <li><a href="http://maps.google.com/?q=12750 Fair Lakes Cir, Fairfax, VA 22033">
                    <!-- <h3>Campus Location:</h3> -->
                    <p>12750 Fair Lakes Cir, Fairfax, VA 22033</p>
                </a></li>
            <li><a href="tel:1-571-633-9651">
                    <!-- <h3>Tel:</h3> -->
                    <p>+1 571-633-9651</p>
                </a></li>
            <li><a href="mailto:info@uona.edu">
                    <!-- <h3>Email:</h3> -->
                    <p>info@uona.edu</p>
                </a></li>
            <li>
                <!-- <h3>Fax:</h3> -->
                <p>+1 703-890-3372</p>
            </li>
        </ul>
        <div class="flex padding" style="background-color:#fff;">
            <!--    <a href="http://home.uona.edu/UoNA/portal/PortalHome.asp" class=button>view our previous website</a>-->
            <a href=/disclosures class=button>Disclosures</a>
        </div>
        <div>Copyright © 2017 - 2018 University of North America All rights reserved.</div>
    </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    //msg: String
  },
  data:function(){
      return {
          //showMobileMenu: false,
      }
      
  },
  methods:{
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
/* footer CSS */
footer{
	background: #f1f1f1;
    overflow: hidden;
}
footer *{
	box-sizing: border-box;
	outline: none;
}

footer ul{
    display:flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    width:100vw;
    list-style-type: none;
    margin:0;
    padding:36px;
}
footer ul li{
    display: flex;
    align-content: center;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding: 20px;
    padding-left: 60px;
    margin: 12px;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: 20px center;
}
footer .phone{
    background-image: url(/assets/icons/phone.svg);
}
footer .email{
    background-image: url(/assets/icons/email.svg);
}
footer .author{
    padding: 48px;
    background: #000;
    color:#fff;
    font-weight: normal !important;
    text-align: left;
        line-height: 30px;
}
footer .author a{
    color: #fff !important;
    font-weight: normal !important;
}
footer .author a:after{
    background-color:#fff !important;
}




@media (max-width: 800px){
    
}
</style>