<template>  
    <header>
        <div @click="openMobileMenu" class="mobile-menu">
            <div class="menu-main-btn">menu</div>
            <ul class="menu-icon">
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div>
        <div v-if="showMobileMenu" class="mobile-submenu">
            <div class="mobile-submenu-scroll">
                <!-- <a href="#" style="padding: 40px 0;">
                    <img src="/resources/img/logo_circle_2.svg" class="logo" alt="logo" onerror="detectedBrokenLink()">
                        
                    </a> -->
                <!--
                        <div class=dropdown>
                            <a href="#" class=main-btn>services</a>
                            <div class="dropdown-menu hide">
                                <a href="#">custom websites</a>
                                <a href="#">logo design</a>
                                <a href="#">other</a>
                            </div>
                        </div>
                        <div class=dropdown>
                            <a href="#" class=main-btn>portfolio</a>
                        </div>
                        -->
                <div class="dropdown" @click="closeMobileMenu">
                    <a href="#" class="main-btn">about</a>
                    <div class="dropdown-menu hide">
                        <router-link to="/about">about us</router-link>
                        <a href="/staff">staff and faculty</a>
                        <a href="/highlights">community</a>
                    </div>
                </div>
                <div class="dropdown" @click="closeMobileMenu">
                    <a href="#" class="main-btn">admission</a>
                    <div class="dropdown-menu hide">
                        <a href="/international">international</a>
                        <a href="/domestic">domestic</a>
                        <a href="/forms">Application Forms</a>
                    </div>
                </div>
                <div class="dropdown" @click="closeMobileMenu">
                    <a href="#" class="main-btn">academics</a>
                    <div class="dropdown-menu hide">
                        <a href="/graduate">graduate</a>
                        <a href="/undergraduate">undergraduate</a>
                        <a href="/certificate/esol">ESOL</a>
                        <a href="/services">student services</a>
                        <a href="/catalogs">catalog</a>
                        <a href="/calendar">calendar</a>
                    </div>
                </div>
                <div class="dropdown" @click="closeMobileMenu">
                    <router-link to="/students" class="main-btn">campus life</router-link>
                </div>
                <div class="dropdown" @click="closeMobileMenu">
                    <router-link to="/contact" class="main-btn">contact</router-link>
                </div>
                <div class="dropdown" @click="closeMobileMenu">
                    <a href="https://login.uona.today" class="main-btn lock">login</a>
                </div>
            </div>

        </div>
        <div v-if="showMobileMenu" class="close" @click="closeMobileMenu">close menu</div>
        <section>
            <router-link to="/">
                <img src="@/assets/images/header_logo.svg" class="logo" alt="logo">
            </router-link>
            <nav>
                <!--
                    <div class=dropdown>
                        <a href="#" class=main-btn>services</a>
                        <div class="dropdown-menu hide">
                            <a href="#">custom websites</a>
                            <a href="#">logo design</a>
                            <a href="#">other</a>
                        </div>
                    </div>
                    <div class=dropdown>
                        <a href="#" class=main-btn>portfolio</a>
                    </div>
                    -->
                <div class=dropdown>
                    <router-link to="/about" class="main-btn">about</router-link>
                    <div class="dropdown-menu hide">
                        <router-link to="/about">about us</router-link>
                        <a href="/staff">staff and faculty</a>
                        <a href="/highlights">community</a>
                    </div>
                </div>
                <div class=dropdown>
                    <router-link to="/admission" class="main-btn">admission</router-link>
                    <div class="dropdown-menu hide">
                        <a href="/international">international</a>
                        <a href="/domestic">domestic</a>
                        <a href="/forms">Application Forms</a>
                    </div>
                </div>
                <div class=dropdown>
                    <a href="#" class="main-btn">academics</a>
                    <div class="dropdown-menu hide">
                        <router-link to="/graduate">graduate</router-link>
                        <router-link to="/undergraduate">undergraduate</router-link>
                        <router-link to="/certificate/esol">ESOL</router-link>
                        <router-link to="/services">student services</router-link>
                        <router-link to="/catalogs">catalog</router-link>
                        <router-link to="/calendar">calendar</router-link>
                    </div>
                </div>
                <div class="dropdown">
                    <router-link to="/students" class="main-btn">campus life</router-link>
                </div>
                <div class="dropdown">
                    <router-link to="/contact" class="main-btn">contact</router-link>
                </div>
                <div class="dropdown">
                    <a href="https://login.uona.today" class="main-btn lock">login</a>
                </div>
                <!--
                    <div style="display: flex;align-items: center;">
                        <a href="https://www.facebook.com/novamotherhoodservices/" style="display:inline-block;">
                            <img src="/resources/img/facebook.svg" alt="Facebook" style="height:40px;margin: 10px;">
                        </a>
                        <a href="https://www.instagram.com/motherhoodservices/" style="display:inline-block;">
                            <img src="/resources/img/instagram.svg" alt="Instagram" style="height:40px;margin: 10px;">
                        </a>
                    </div>
                    -->
            </nav>

        </section>
    </header>
</template>

<script>
export default {
  name: 'Header',
  data:function(){
      return {
          showMobileMenu: false,
      }
      
  },
  methods:{
      openMobileMenu(){
          this.showMobileMenu = true;
      },
      closeMobileMenu(){
          this.showMobileMenu = false;
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
header{
    position: relative;
    width: 100%;
    background:#4067b1 !important;
    border-top:10px solid #fbd504;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.04), 0 6px 30px 5px rgba(0, 0, 0, 0.02), 0 8px 10px -5px rgba(0, 0, 0, 0.1);
}
header *{
    box-sizing: border-box;
    outline:none;
}
.maintitle p {
    font-size: 40px !important;
    border-top: 0 !important;
}
section{
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	padding:0px;
	align-items: center;
    /* box-shadow: 0 5px 20px rgba(0,0,0,.2); */
}
a{
    margin:0;
}
.logo{
    height:140px;
    display: block;
    padding:20px;
}
nav{
	display:flex;
	justify-content: space-around;
	float:right;
	/*background: hsla(0, 0%, 0%, 0.7);*/
}
.lock{
    background-image: url(../assets/images/lock.svg) !important;
    background-size: 50px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}
.dropdown {
    display: inline-block;
    position: relative;
}

.main-btn {
    font-family: 'Oswald', sans-serif;
    font-weight:normal;
    border: 0;
    padding: 20px;
    color: hsla(0,0%,100%,1);
    font-size: 20px;
    background:transparent;
    cursor:pointer;
    border-bottom:5px solid transparent;
    text-transform: uppercase;
    text-decoration: none;
    display: block;
    position:relative;
}

.dropdown:hover > .main-btn,
.dropdown:focus > .main-btn{
	color:var(--accent-color, "#000");
}

.main-btn:after{
	content:"";
	height:5px;
	position:absolute;
	width:0;
	bottom:-5px;
	left:0;
	background:var(--accent-color, "#000");
	box-sizing: inherit;
	transition:.3s;
    opacity:0;
    border-radius:0px;
}
.dropdown:hover > .main-btn:after,
.dropdown:focus > .main-btn:after{
	width:100%;
    opacity:1;
}

.dropdown-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    min-width: 180px;
    z-index: 1;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, .2);
    padding-bottom:10px;
    text-transform: capitalize;
    background:#fff;
    opacity:0;
    transition:.3s;
    overflow: hidden;
}

.dropdown:hover > .dropdown-menu,
.dropdown:focus > .dropdown-menu{
    display:block !important;
    animation: menu .3s forwards;
}
@keyframes menu {
    from {opacity:0;}
    to {opacity:1;}
}

.dropdown-menu button,
.dropdown-menu a{
    font-family: 'Roboto', sans-serif;
    border: 0;
    padding: 10px 15px;
    cursor:pointer;
    text-align:left;
    text-transform: capitalize;
    text-decoration: none;
    display: block;
    color:#000;
}

.dropdown-menu button:hover,
.dropdown-menu a:hover{
    background: rgba(0,0,0,.05);
    color:#000;
    /*color:var(--accent-color, "#000");*/
}

.hide {
  display: none !important;
}
.mobile-menu,
.mobile-submenu{
    display:none;
}
.close{
    display:none;
}

@media (max-width: 800px){
    header{
        overflow: auto;
    }
    nav{
        display:none;
    }
    .lock{
        background-image: url(/images/lock.svg) !important;
        background-size: 40px !important;
        background-repeat: no-repeat !important;
        background-position: center right 20px !important;
    }
    section:nth-child(1){
        margin-top:100px;
    }
    .main-btn{
        padding: 15px 25px;
    }
    .dropdown{
        display: inline-block;
        position: relative;
        width: 80vw;
        background-color: hsla(0, 0%, 0%, 0.1);
        border-radius: 0px;
        margin: 5px 0;
    }
    .dropdown-menu{
        width:100%;
    }
    .dropdown-menu a{
        padding:20px 25px;
    }
    .mobile-menu{
        position: relative;
        top: 0;
        right: 0;
        padding: 0 48px;
        width: 100%;
        background-color: rgba(0,0,0,.5);
        height: 80px;
        color: #fff;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        font-weight: bold;
        justify-content: space-between;
        font-size:20px;
        list-style-type: none;
        font-family: sans-serif;
    }
    header .menu-icon {
      padding: 0;
      margin: 0;
      list-style: none;
      width: 50px;
      height: 30px;
      display: flex;
      flex-flow: row wrap;
      align-content: space-between; 
      position:relative;
      transition:.3s;
      float:right;
    }

    header .menu-icon > *{
      background: #fff;
      width: 100%;
      height: 5px;
      border-radius:50px;
    }
    header .mobile-submenu{
		width:100vw;
		min-height:100vh;
		background-color:#4067b1;
		position:fixed;
		top:0;
		left:0;
		display:flex;
        z-index: 3;
        flex-direction: column;
        align-items: center;
	}
    header .close{
        height: 80px;
        background: #000;
        position: fixed;
        z-index: 3;
        right: 0;
        top: calc(100% - 80px);
        width: 100vw;
        display: flex;
        text-transform: uppercase;
        color: #fff;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 20px;
        font-family: sans-serif;
    }
    header .mobile-submenu-scroll{
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        height: calc(100vh - 80px);
        width: 100vw;
        align-items: center;
        padding-bottom:250px;
            padding-top: calc(10vw - 5px);
    }

}
@media (max-width: 500px){
    header .logo{
        height:120px;
    }
    .maintitle p {
        font-size: 25px !important;
        border-top: 0 !important;
    }
}
</style>