<template>
    <div el="layout-hmf">
        <Header/>
        <slot></slot>
        <Footer/>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
    name:'Layout',
    components: { Header, Footer },
    setup() {
        return {
            
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
// import core
@import '../assets/scss/core';
</style>