<template>
  <main>
    <ul class="floatingBar">
        <li></li>
        <li>
            <a href="https://una-web.scansoftware.com/cafeweb/tapestry?page=Degree%20Application">apply now</a>
            <a href="/contact">contact us</a>
            <a href="#" onclick="scrollToPrograms()">programs</a>
            <a href="/calendar">calendar</a>
            <a href="/military">military</a>
        </li>
    </ul>
  </main>
</template>

<script>
export default {
  name: "Home",
  components: {
    // HelloWorld,
  },
};
</script>
