<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <Layout>
    <router-view />
  </Layout>
</template>
<script>
import Layout from '@/components/Layout.vue'

export default {
  components: { Layout },
  // setup() {
  //   return {
      
  //   }
  // },
}
</script>

<style lang="scss">
@import 'assets/scss/style.scss';

body{
  margin: 0;
  padding: 0;
}
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
